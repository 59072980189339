<template> 
    <b-card-code>
    <b-card
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >

      <b-card-body>
        <b-card-title>Permissions</b-card-title>
        <b-card-sub-title>Permission according to roles</b-card-sub-title>
      </b-card-body>
      <b-table
        striped
        responsive
        :items="permissionsData"
        class="mb-0"
        :sticky-header="true"
      >
        <template #cell(name)="data" >
          {{ data.value }}
        </template>

        <template #cell()="data">
          <b-form-checkbox
            :checked="data.value"
            @change="sentData(data.field.key, data.index)"
          />
        </template>
      </b-table>


      <b-button

        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class=" mt-5"
        type="submit"
        @click="editPermission"
      >
        Update
      </b-button>

      </b-overlay>
    </b-card>
  </b-card-code>
  </template>
  
  <script>
  import {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BOverlay,
  } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import DataService  from './data/services'
  import Ripple from 'vue-ripple-directive'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  export default {
    components: {
      BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, ToastificationContent, BButton, BOverlay, BCardCode,
    },
    directives: {
      Ripple,
    },
    data() {
        return {
          permissionsData: [],
          isLoading: true,
        }
    },

    methods: {
        getData(){
          this.isLoading = true;
          DataService.getPermissions()
          .then(response => {
              this.isLoading = false
              this.permissionsData = response.data.data
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'successfully',
                  icon: 'BellIcon',
                  text: response.data.responseDescription,
                  variant: 'success',
                  },
              })
          })
        },
        editPermission(){
          this.isLoading = true;
          DataService.sendPermission(this.permissionsData)
          .then(response => {
              this.isLoading = false
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'successfully',
                  icon: 'BellIcon',
                  text: response.data.responseDescription,
                  variant: 'success',
                  },
              })
          })
        },
        sentData(data, index){
          this.permissionsData[index][data] = !this.permissionsData[index][data] 
        }
    },
    mounted() {
      this.getData()
    },
  
  }
  </script>
  
  <style>
  
  </style>
  