import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/admins");
  }
  get(id) {
    return axios.get(`/admins/${id}`);
  }
  getPermissions() {
    return axios.get("/permissions");
  }
  sendPermission(data) {
    return axios.post('permissions', data);
  }
  getadminsType(id) {
    return axios.get(`/adminType`);
  }

  create(data) {
    return axios.post("/admins", data);
  }
  update(id, data) {
    return axios.put(`/admins/${id}`, data);
  }

  changePassword(id) {
    return axios.get(`/changePassword/${id}`);
  }

  getAdminType() {
    return axios.get("/lkbAdminType");
  }

}
export default new DataService();